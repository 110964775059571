var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['header', _vm.isScrolled ? 'scrolled' : null]
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'Home'
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "assets/images/logo/logo-svg.svg",
      "alt": "tamayoz-logo",
      "width": "32px"
    }
  })])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }